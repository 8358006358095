import { ACCESS_TOKEN } from '../constants';
import jwt_decode from 'jwt-decode';

class UserManager {
    getUser() {
        const token = this.getUserToken();

        if (token === null) {
            return null;
        }

        return jwt_decode(token).user;
    }

    getUserHash() {
        const user = this.getUser();

        if (user === null) {
            return null;
        }

        return user.token;
    }

    getUserToken() {
        return localStorage.getItem(ACCESS_TOKEN);
    }
}

export default new UserManager();
