export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const cognitoConfig = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID
};

export const auth0Config = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;

export const googleAnalyticsConfig = 'G-SWKKTKJN2M';

// export const apiHost = process.env.API_HOST;
export const apiHost = 'https://api.atvido.com';
export const paymentApiHost = 'https://payments-api.atvyko.lt';
export const userApiHost = 'https://user-api.atvido.com';
export const defaultPaymentProviderHash = '77d8afb4-76e7-498b-994a-d057229f76ae';
export const host = 'https://app.atvido.com';
export const stripePublishableKey = 'pk_live_51NsmksBuDqRlOGJhNnrdRMzF7qUJqmuOefZaRsTE1vpkbsmP66Wtn3NePwmoEUxs7MRaXVbfHUpsirMUdBvgBpDu00AuDkrJyw';

// export const apiHost = 'http://atvido-uk';
// export const userApiHost = 'http://atvyko-user-api';
// export const paymentApiHost = 'http://payments-api';
// export const defaultPaymentProviderHash = 'paysera_hash';
// export const host = 'http://localhost:3000';
// export const stripe_publishable_key = 'pk_test_51JISEuAy5RdH2upT7LCvGxGKli8dMhtxUaO4LvplSBS74R31YndsYmQ47Q90HcGo12TUgi3GAsJCW14fiaMHgNJa007DLFvVc6';
