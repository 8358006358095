import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@material-ui/core/styles';
import {
  Box,
  Card,
  Stack,
  Link,
  Container,
  Typography,
} from '@material-ui/core';
import { PATH_AUTH } from '../../routes/paths';
import Page from '../../components/Page';
import { MHidden } from '../../components/@material-extend';
import { LoginForm } from '../../components/authentication/login';
import trans from '../../services/trans';
import SocialLogins from "./SocialLogins";

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2)
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  minHeight: '100vh',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 0)
}));

const CreateAccountContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 0)
}));

export default function Login() {

  return (
    <RootStyle title="Atvido.com">
      <MHidden width="mdDown">
        <SectionStyle>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            { trans('login.title') }
          </Typography>
          <img src="/static/illustrations/illustration_login.svg" alt="login" />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack direction="row" alignItems="center" sx={{ mb: 5 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h4" gutterBottom>
                { trans('login.name') }
              </Typography>
              <Typography sx={{ color: 'text.secondary' }}>{ trans('login.provide_data') }</Typography>
            </Box>
          </Stack>

          <SocialLogins />
          <LoginForm />

          <CreateAccountContainer>
            <Typography variant="body2" align="center">
              { trans('login.register.have_no_account') } &nbsp;
              <Link variant="subtitle2" component={RouterLink} to={PATH_AUTH.register}>
                { trans('login.register.have_no_account_create') }
              </Link>
            </Typography>

          </CreateAccountContainer>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
