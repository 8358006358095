const getPriceFromFee = (feeName, fees) => {
    const findFee = fees.find(item => item.type === feeName);

    if (findFee === undefined) {
        return null;
    }

    return  findFee.price;
};

export default (checkout, fees) => {
    const { userSelectedFees, cart } = checkout;

    let totalAmount = 0;

    userSelectedFees.forEach(userSelectedFee => {
        const price = getPriceFromFee(userSelectedFee, fees);

        if (price === null) {
            return;
        }

        totalAmount += cart.length * price;
    });

    return totalAmount;
};
