import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import Router from './routes';
import ThemeConfig from './theme';
import useAuth from './hooks/useAuth';
import RtlLayout from './components/RtlLayout';
import ScrollToTop from './components/ScrollToTop';
import LoadingScreen from './components/LoadingScreen';
import GoogleAnalytics from './components/GoogleAnalytics';
import NotistackProvider from './components/NotistackProvider';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import ThemeLocalization from './components/ThemeLocalization';
import Fingerprint from "./components/Fingerprint";
import Referral from "./components/Referral";
// import FacebookPixel from "./components/FacebookPixel";

// Sentry.init({
//   dsn: "http://0ace380bea8b4482af00a590f44c1cde@sentry.spiliauskas.lt/11",
//   integrations: [new BrowserTracing()],
//   tracesSampleRate: 1.0,
// });

export default function App() {
  const { isInitialized } = useAuth();

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <RtlLayout>
            <NotistackProvider>
              <Referral />
              <ScrollToTop />
              <GoogleAnalytics />
              <Fingerprint />
              {isInitialized ? <Router /> : <LoadingScreen />}
            </NotistackProvider>
          </RtlLayout>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
