import {REFERRAL_CODE} from "../constants";

export default function Referral() {
    const queryString = window.location.search;

    if (queryString === '') {
        return null;
    }

    const urlParams = new URLSearchParams(queryString);
    const referralCode = urlParams.get('referral_code');

    if (referralCode === null) {
        return null;
    }

    localStorage.setItem(REFERRAL_CODE, referralCode);

    return null;
}
