import axios from 'axios';
import { apiHost } from '../config';

const axiosInstance = axios.create({
    'baseURL': apiHost,
});

axiosInstance.interceptors.response.use(
  (response) => {
      return response;
  },
  (error) => {
      return Promise.reject((error.response && error.response.data) || 'Something went wrong')
  }
);

export default axiosInstance;
