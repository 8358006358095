import {
    ORDER_STATUS_PROCESSING_PAYMENT,
    ORDER_STATUS_SORTED_LITHUANIA,
    ORDER_TYPE_PURCHASED_FOR_USERS,
    ORDER_TYPE_PURCHASES_HIMSELF,
    ORDER_TYPE_PACKAGE_RETURN,
} from "../../constants";

const STATUS_ABROAD = 'abroad';
const STATUS_HOME = 'home';
const STATUS_PAID = 'paid';
const STATUS_PURCHASES_FOR_CLIENTS = 'purchases_for_clients';

const STATUS_POLAND = 'poland';
const STATUS_ENGLAND = 'uk';
const STATUS_GERMANY = 'germany';
const STATUS_LITHUANIA = 'lithuania';
const STATUS_PREPARED_TO_COLLECT = 'prepared_to_collect';
const STATUS_FINISHED = 'finished';
const STATUS_READY_TO_PAY = 'ready_to_pay';

const GLOBAL_STATUS_MAP_FOR_QUERY = {
    [STATUS_GERMANY]: {
        statuses: [
            STATUS_GERMANY,
            STATUS_READY_TO_PAY,
        ],
        types: [ORDER_TYPE_PURCHASES_HIMSELF],
    },
    [STATUS_ENGLAND]: {
        statuses: [
            STATUS_ENGLAND,
            STATUS_READY_TO_PAY,
        ],
        types: [ORDER_TYPE_PURCHASES_HIMSELF],
    },
    [STATUS_POLAND]: {
        statuses: [
            STATUS_POLAND,
            STATUS_READY_TO_PAY,
        ],
        types: [ORDER_TYPE_PURCHASES_HIMSELF],
    },
    [STATUS_PAID]: {
        statuses: [
            STATUS_PAID,
            STATUS_PREPARED_TO_COLLECT,
            STATUS_FINISHED,
            ORDER_STATUS_PROCESSING_PAYMENT,
        ],
        types: [
            ORDER_TYPE_PURCHASES_HIMSELF,
            ORDER_TYPE_PACKAGE_RETURN,
        ],
    },
};

const STATUS_MAP = {
    [STATUS_ABROAD]: [
        STATUS_POLAND,
        STATUS_ENGLAND
    ],
    [STATUS_HOME]: [
        STATUS_LITHUANIA
    ],
};

export {
    STATUS_ABROAD,
    STATUS_HOME,
    STATUS_PAID,
    STATUS_PURCHASES_FOR_CLIENTS,

    GLOBAL_STATUS_MAP_FOR_QUERY,

    STATUS_POLAND,
    STATUS_ENGLAND,
    STATUS_GERMANY,
    STATUS_LITHUANIA,
    STATUS_PREPARED_TO_COLLECT,
    STATUS_FINISHED,
    STATUS_MAP,
};
