import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ pl: 2, pb: -2, height: 50, width: 155, ...sx }} component="img" alt="logo" src={'/static/icons/logo.svg'} />
  );
}
