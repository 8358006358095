import {useEffect} from "react";
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { LOCAL_STORAGE_VISITOR_ID } from "../constants";

export default function Fingerprint() {
    useEffect(
        () => {
            (async () => {
                try {
                    const fpPromise = FingerprintJS.load();

                    const fp = await fpPromise;
                    const result = await fp.get();

                    localStorage.setItem(
                        LOCAL_STORAGE_VISITOR_ID,
                        result?.visitorId === null ? 'not_identified' : result?.visitorId
                    );
                } catch (e) {
                    localStorage.setItem(LOCAL_STORAGE_VISITOR_ID,  'not_identified');
                }
            })();
        },
        []
    );

    return null;
}
