import * as React from 'react';
import {
    BottomNavigation,
    BottomNavigationAction,
    ListItemIcon,
    Paper
} from '@material-ui/core';
import sidebarConfig from './SidebarConfig';
import {alpha, styled, useTheme} from "@material-ui/core/styles";
import {matchPath, useLocation, useNavigate} from "react-router-dom";
import {useState} from "react";

const ListItemIconStyle = styled(ListItemIcon)({
    width: 22,
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 0,
});

export default function MobileNavbar() {
    const navigate = useNavigate();
    const theme = useTheme();
    const { pathname } = useLocation();
    const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

    const activeRootStyle = {
        color: 'primary.main',
        fontWeight: 'fontWeightMedium',
        bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
        bgColor: 'black',
        '&:before': { display: 'block' }
    };

    const handleChange = (event, path) => {
        navigate(path);
    };

    return (
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex: 1000 }} elevation={4}>
            <BottomNavigation onChange={handleChange}>
                {sidebarConfig[0].items.filter(item => item.displayed).map(item => (
                    <BottomNavigationAction
                        key={item.title}
                        value={item.path}
                        label={item.mobile_title}
                        showLabel
                        icon={
                            <ListItemIconStyle
                                sx={match(item.path) && activeRootStyle}
                            >
                                {item.icon}
                            </ListItemIconStyle>
                        }
                    />
                ))}
            </BottomNavigation>
        </Paper>
    );
}
