import React from 'react';
import { Icon } from '@iconify/react';
import {Button, Divider, Stack, Typography} from "@material-ui/core";
import googleFill from "@iconify/icons-eva/google-fill";
import facebookFill from "@iconify/icons-eva/facebook-fill";
import trans from "../../services/trans";
import { userApiHost } from '../../config';
import {REFERRAL_CODE} from "../../constants";

export default () => {
    const redirectToLogin = (provider) => {
        let redirectLink = `${userApiHost}/social/login/${provider}/system/atvido`;

        const referralCode = localStorage.getItem(REFERRAL_CODE);
        if (referralCode !== null) {
            redirectLink = `${redirectLink}?referral_code=${referralCode}`;
        }

        window.location.href = redirectLink;
    };

    return (
        <>
            <Stack direction="row" spacing={2}>
                <Button
                    fullWidth
                    size="large"
                    color="inherit"
                    variant="outlined"
                    onClick={() => redirectToLogin('google')}
                >
                    <Icon icon={googleFill} color="#DF3E30" height={24} />
                </Button>

                <Button
                    fullWidth size="large"
                    color="inherit"
                    variant="outlined"
                    onClick={() => redirectToLogin('facebook')}
                >
                    <Icon icon={facebookFill} color="#1877F2" height={24} />
                </Button>
            </Stack>

            <Divider sx={{ my: 3 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    {trans('login.social.or')}
                </Typography>
            </Divider>
        </>
    );
}
