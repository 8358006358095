import { PATH_DASHBOARD } from '../../routes/paths';
import SvgIconStyle from '../../components/SvgIconStyle';
import trans from '../../services/trans';
import {STATUS_ENGLAND} from "../../pages/dashboard/constants";
import {
    MENU_ITEM_MY_ACCOUNT,
    MENU_ITEM_MY_SHIPMENTS,
    MENU_ITEM_USER_INFORMATION,
    MENU_ITEM_WAREHOUSE,
    MENU_ITEM_PACKAGE_RETURN,
} from "./constants";

const getIcon  = (name) => (
    <SvgIconStyle
        src={`/static/icons/navbar/${name}`}
    />
);

const ICONS = {
    dashboard: getIcon('dashboard.svg'),
    ecommerce: getIcon('pin.svg'),
    analytics: getIcon('box.svg'),
    user: getIcon('user.svg'),
    packageReturn: getIcon('undo.svg'),
};

const sidebarConfig = [
    {
        subheader: trans('dashboard.sidebar.title'),
        items: [
            {
                title: trans('dashboard.sidebar.my_account.title'),
                path: PATH_DASHBOARD.general.app,
                icon: ICONS.dashboard,
                name: MENU_ITEM_MY_ACCOUNT,
                mobile_title: trans('dashboard.sidebar.my_account_mobile.title'),
                displayed: true,
            },
            {
                title: trans('dashboard.sidebar.warehouse.title'),
                path: PATH_DASHBOARD.general.ecommerce,
                icon: ICONS.ecommerce,
                name: MENU_ITEM_WAREHOUSE,
                mobile_title: trans('dashboard.sidebar.warehouse_mobile.title'),
                displayed: true,
            },
            {
                title: trans('dashboard.sidebar.my_shipments.title'),
                path: `${PATH_DASHBOARD.user.root}/orders/${STATUS_ENGLAND}`,
                icon: ICONS.analytics,
                name: MENU_ITEM_MY_SHIPMENTS,
                mobile_title: trans('dashboard.sidebar.my_shipments_mobile.title'),
                displayed: true,
            },
            {
                title: trans('dashboard.sidebar.user_info.title'),
                path: PATH_DASHBOARD.user.account,
                icon: ICONS.user,
                name: MENU_ITEM_USER_INFORMATION,
                mobile_title: trans('dashboard.sidebar.user_info_mobile.title'),
                displayed: true,
            },
            {
                title: trans('dashboard.sidebar.package_return.title'),
                path: PATH_DASHBOARD.packageReturn.list,
                icon: ICONS.packageReturn,
                name: MENU_ITEM_PACKAGE_RETURN,
                mobile_title: trans('dashboard.sidebar.package_return_mobile.title'),
                displayed: true,
            }
        ]
    },
];

export default sidebarConfig;
