const ACCESS_TOKEN = 'accessToken';

const ORDER_STATUS_POLAND = 'poland';
const ORDER_STATUS_GERMANY = 'germany';
const ORDER_STATUS_UK = 'uk';
const ORDER_STATUS_SORTED_LITHUANIA = 'sorted_lithuania';
const ORDER_STATUS_LITHUANIA = 'lithuania';
const ORDER_STATUS_PAID = 'paid';
const ORDER_STATUS_PREPARED_TO_COLLECT = 'prepared_to_collect';
const ORDER_STATUS_FINISHED = 'finished';
const ORDER_STATUS_UK_PAID = 'uk_paid';
const ORDER_STATUS_UK_CREATED = 'uk_created';
const ORDER_STATUS_UK_ORDERED = 'uk_ordered';
const ORDER_STATUS_UK_DELIVERED = 'uk_delivered';
const ORDER_STATUS_PROCESSING_PAYMENT = 'processing_payment';

const ORDER_TYPE_PURCHASES_HIMSELF = 'purchases_himself';
const ORDER_TYPE_PACKAGE_RETURN = 'package_return';
const ORDER_TYPE_PURCHASED_FOR_USERS = 'purchased_for_users';

const SHIPPING_TYPE_COLLECTS_SELF = 'collects_self';
const SHIPPING_TYPE_TERMINAL = 'terminal';
const SHIPPING_TYPE_HOME = 'home';

const REFERRAL_CODE = 'referral_code';

const FEE_TYPE_MERGE = 'merge';
const FEE_TYPE_INVOICE_REMOVAL = 'invoice_removal';
const FEE_TYPE_LABEL_REMOVAL = 'label_removal';
const FEE_TYPE_USER_APPROVAL = 'user_approval';
const FEE_TYPE_DELIVER_TO_DOOR = 'deliver_to_door';

const VAT_LITHUANIA = 21;

const SHIPMENT_TYPE_HOME = 'home';
const SHIPMENT_TYPE_HOME_TERRESTRIAL = 'home_terrestrial';
const SHIPMENT_TYPE_HOME_TERRESTRIAL_EXPRESS_PAK = 'home_terrestrial_express_pak';
const SHIPMENT_TYPE_HOME_EXPRESS = 'home_express';
const SHIPMENT_TYPE_POST = 'parcel';
const SHIPMENT_TYPE_SELF = 'collects_self';

const SHIPMENT_PROVIDER_DPD = 'dpd';
const SHIPMENT_PROVIDER_DHL = 'dhl';
const SHIPMENT_PROVIDER_ATVYKO = 'atvyko';

const PAYMENT_PROVIDER_PAYSERA = 'paysera';
const PAYMENT_PROVIDER_STRIPE = 'stripe';

const PAYMENT_REQUEST_TYPE_REDIRECT = 'redirect';
const PAYMENT_REDIRECT_TO_PROVIDER = 'redirect_to_provider';
const PAYMENT_CARD_CHECKOUT = 'card_checkout';

const OFFERED_PRODUCT_STATUS_DISPLAYED = 'displayed';
const OFFERED_PRODUCT_TYPE_MAIN = 'main';
const OFFERED_PRODUCT_TYPE_CAROUSEL_MAIN = 'carousel_main';
const OFFERED_PRODUCT_TYPE_CAROUSEL_SALE = 'carousel_sale';

const WAREHOUSE_ADDRESS_GOOGLE_MAP = 'https://www.google.com/maps/place/Atvyko/@54.662438,25.2526323,17z/data=!3m1!4b1!4m5!3m4!1s0x46dd9567eb4502b1:0x28615baed582bf99!8m2!3d54.6624335!4d25.2548833';

const LOCAL_STORAGE_VISITOR_ID = 'visitor_id';

const COUNTRY_CODE_ENGLAND = 'GB';
const COUNTRY_CODE_GERMANY = 'DE';
const COUNTRY_CODE_POLAND = 'PL';

const ORDER_ITEM_SHIPMENT_TYPE_GIFT = 'gift';
const ORDER_ITEM_SHIPMENT_TYPE_PURCHASE = 'purchase';
const ORDER_ITEM_SHIPMENT_TYPE_OTHER = 'other';

let SHIPMENT_REQUIREMENTS = [
    {
        provider: SHIPMENT_PROVIDER_DHL,
        type: SHIPMENT_TYPE_HOME,
        maxWeight: 1000,
        maxLength: null,
        countries: null,
    },
    {
        provider: SHIPMENT_PROVIDER_DHL,
        type: SHIPMENT_TYPE_HOME_EXPRESS,
        maxWeight: 1000,
        maxLength: null,
        countries: null,
    },
    {
        provider: SHIPMENT_PROVIDER_DPD,
        type: SHIPMENT_TYPE_HOME_TERRESTRIAL,
        maxWeight: 31.5,
        maxLength: null,
        countries: [
            'DE',
            'FR',
            'IT',
            'ES',
            'AT',
            'IE',
            'NL',
        ],
        girth: 111,
    },
    {
        provider: SHIPMENT_PROVIDER_DPD,
        type: SHIPMENT_TYPE_HOME_TERRESTRIAL_EXPRESS_PAK,
        maxWeight: 3,
        maxLength: null,
        countries: [
            'DE',
            'FR',
            'IT',
            'ES',
            'AT',
            'NL',
        ],
        girth: 300,
    },
];

const GBP_CURRENCY_SYMBOL = '£';
const EUR_CURRENCY_SYMBOL = '€';

const EUR_CURRENCY = 'EUR';
const GBP_CURRENCY = 'GBP';

const CURRENCY_SYMBOL_MAP = {
    [GBP_CURRENCY]: GBP_CURRENCY_SYMBOL,
    [EUR_CURRENCY]: EUR_CURRENCY_SYMBOL,
};

const PRODUCT_TYPE_PHYSICAL = 'physical';
const PRODUCT_TYPE_DIGITAL = 'digital';

const WEIGHT_DELIVERY_TO_DOOR = 32;
const WEIGHT_DELIVERY_TO_DOOR_CHANGE_PRICE = 70;

const CATEGORY_POLAND = 'poland';
const CATEGORY_UK = 'uk';
const CATEGORY_DE = 'de';

const VAT_TYPE_APPLIED_FOR_ALL = 'applied_for_all';
const VAT_TYPE_APPLIED_FOR_COMPANIES = 'applied_for_companies';

const DEFAULT_PRODUCT_WEIGHT = 55555;

const PACKAGE_RETURN_STATUS_PREPARED_TO_DELIVER = 'prepared_to_deliver';
const PACKAGE_RETURN_STATUS_DELIVERED_TO_WAREHOUSE = 'delivered_to_warehouse';
const PACKAGE_RETURN_STATUS_DELIVERED_TO_DESTINATION_WAREHOUSE = 'delivered_to_destination_warehouse';
const PACKAGE_RETURN_SENT_TO_FINAL_DESTINATION = 'sent_to_final_destination';

const ORDER_ITEM_SHIPMENT_TYPES = {
    ORDER_ITEM_SHIPMENT_TYPE_GIFT,
    ORDER_ITEM_SHIPMENT_TYPE_PURCHASE,
    ORDER_ITEM_SHIPMENT_TYPE_OTHER
}

export {
    ACCESS_TOKEN,

    ORDER_STATUS_POLAND,
    ORDER_STATUS_GERMANY,
    ORDER_STATUS_UK,
    ORDER_STATUS_SORTED_LITHUANIA,
    ORDER_STATUS_LITHUANIA,
    ORDER_STATUS_PAID,
    ORDER_STATUS_PREPARED_TO_COLLECT,
    ORDER_STATUS_FINISHED,
    ORDER_STATUS_UK_PAID,
    ORDER_STATUS_UK_CREATED,
    ORDER_STATUS_UK_ORDERED,
    ORDER_STATUS_UK_DELIVERED,
    ORDER_STATUS_PROCESSING_PAYMENT,

    SHIPPING_TYPE_COLLECTS_SELF,
    SHIPPING_TYPE_TERMINAL,
    SHIPPING_TYPE_HOME,

    FEE_TYPE_MERGE,
    FEE_TYPE_INVOICE_REMOVAL,
    FEE_TYPE_LABEL_REMOVAL,
    FEE_TYPE_USER_APPROVAL,
    FEE_TYPE_DELIVER_TO_DOOR,

    VAT_LITHUANIA,

    SHIPMENT_TYPE_HOME,
    SHIPMENT_TYPE_HOME_TERRESTRIAL,
    SHIPMENT_TYPE_HOME_TERRESTRIAL_EXPRESS_PAK,
    SHIPMENT_TYPE_HOME_EXPRESS,
    SHIPMENT_TYPE_POST,
    SHIPMENT_TYPE_SELF,

    SHIPMENT_PROVIDER_DPD,
    SHIPMENT_PROVIDER_DHL,
    SHIPMENT_PROVIDER_ATVYKO,

    PAYMENT_PROVIDER_PAYSERA,
    PAYMENT_PROVIDER_STRIPE,

    PAYMENT_REQUEST_TYPE_REDIRECT,
    PAYMENT_REDIRECT_TO_PROVIDER,
    PAYMENT_CARD_CHECKOUT,
    SHIPMENT_REQUIREMENTS,

    OFFERED_PRODUCT_STATUS_DISPLAYED,

    PRODUCT_TYPE_PHYSICAL,
    PRODUCT_TYPE_DIGITAL,

    WEIGHT_DELIVERY_TO_DOOR,
    WEIGHT_DELIVERY_TO_DOOR_CHANGE_PRICE,

    CATEGORY_POLAND,
    CATEGORY_UK,
    CATEGORY_DE,

    OFFERED_PRODUCT_TYPE_MAIN,
    OFFERED_PRODUCT_TYPE_CAROUSEL_MAIN,
    OFFERED_PRODUCT_TYPE_CAROUSEL_SALE,

    WAREHOUSE_ADDRESS_GOOGLE_MAP,
    VAT_TYPE_APPLIED_FOR_ALL,
    VAT_TYPE_APPLIED_FOR_COMPANIES,

    ORDER_TYPE_PURCHASES_HIMSELF,
    ORDER_TYPE_PURCHASED_FOR_USERS,
    ORDER_TYPE_PACKAGE_RETURN,

    DEFAULT_PRODUCT_WEIGHT,
    LOCAL_STORAGE_VISITOR_ID,

    PACKAGE_RETURN_STATUS_PREPARED_TO_DELIVER,
    PACKAGE_RETURN_STATUS_DELIVERED_TO_WAREHOUSE,
    PACKAGE_RETURN_STATUS_DELIVERED_TO_DESTINATION_WAREHOUSE,
    PACKAGE_RETURN_SENT_TO_FINAL_DESTINATION,

    COUNTRY_CODE_ENGLAND,
    COUNTRY_CODE_GERMANY,
    COUNTRY_CODE_POLAND,

    REFERRAL_CODE,
    CURRENCY_SYMBOL_MAP,

    GBP_CURRENCY,
    EUR_CURRENCY,

    ORDER_ITEM_SHIPMENT_TYPES
}