import {Box, Grid} from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";

export default function LoyaltyPoints() {
    const { user } = useAuth();
    const navigate = useNavigate();

    let loyaltyPoints = 0;
    if (user.loyalty_points?.points !== undefined) {
        loyaltyPoints = user.loyalty_points?.points;
    }

    return (
        <Box
            sx={{ my: 1.5, color: 'black', cursor: 'pointer' }}
            onClick={() => navigate('/dashboard/user/account?tab=referral')}
        >
            <Grid container>
                <Grid item sx={{ pr: 1 }}>
                    {loyaltyPoints}
                </Grid>
                <Grid item>
                    <img
                        src={"/static/illustrations/euro.png"}
                        alt="euro coin"
                        width="24"
                        height="24"
                    />
                </Grid>
            </Grid>
        </Box>
    );
}
